@import "~@/erp/styles/variables/variables.scss";




























































































































































































































































































































.login {
  width: 100%;
  min-width: 960px;
  min-height: 550px;
  height: 100% !important;
  background: #ffffff;
  position: relative;
  overflow: auto;

  .logo {
    height: 32px;
    margin-top: 20px;
    margin-left: 30px;

    img {
      width: 122px;
    }
  }

  .login-content {
    width: 960px;
    height: 464px;
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 2px 11px 6px rgba(234, 234, 234, 0.5);
    border-radius: 4px;
    overflow: hidden;
  }

  .left_bg {
    width: 495px;
    height: 100%;
    position: relative;
    background-image: url('../../assets/login_images/login-left.png');
    background-size: cover;
    background-position: center;

    &.en {
      background-image: url('../../assets/login_images/login-left-en.png');
    }
  }

  &-con {
    width: 465px;
    height: 100%;
    position: relative;
    background: #fff;
    padding: 15px 40px 0;

    &-header {
      font-size: 16px;
      font-weight: 300;
      text-align: center;
    }

    .form-con {
      padding: 10px 0 0;
    }

    .login-tip {
      font-size: 10px;
      text-align: center;
      color: #c3c3c3;
    }

    .login-btn {
      width: 100%;
      height: 40px;
      background: #236ce9;
      border-radius: 4px;
      font-size: 14px;
      font-family: 'PingFang Bold';
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.title {
  font-size: 21px;
  margin-bottom: 30px;
  font-family: 'PingFang Bold';
  color: #282c34;
  padding-bottom: 13px;
  border-bottom: 1px solid #282c34;
}

.copy-right {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #a4b4d4;
  line-height: 34px;
  border-top: 1px solid #d0d7de;

  span {
    cursor: pointer;
    margin-left: 5px;
  }
}

::v-deep {
  .el-input__inner {
    height: 46px !important;
    background: #ffffff;
    border-radius: 2.5px;
    border: 1px solid #dcdfe6;
    line-height: 46px;

    &::-webkit-input-placeholder {
      color: #777;
    }

    &::-moz-input-placeholder {
      color: #777;
    }

    &::-ms-input-placeholder {
      color: #777;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    }
  }

  .el-input__prefix {
    line-height: 46px;
    padding: 0 5px;

    i {
      font-size: 18px;
      line-height: inherit;
      color: #999;
    }
  }
}
