@import "~@/erp/styles/variables/variables.scss";






























































































































.password-check-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  .check-label {
    font-family: 'PingFang Bold';
  }
  .el-row {
    flex: 1;
    margin-left: 10px;
    border-radius: 2px;
  }
}

.el-col {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 2px;
}
