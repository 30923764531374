@import "~@/erp/styles/variables/variables.scss";






























































































































































































$bold: 'PingFang Bold';
.login-content {
  width: 960px;
  height: 464px;
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 2px 11px 6px rgba(234, 234, 234, 0.5);
  border-radius: 4px;
  overflow: hidden;
  .tips {
    display: flex;
    background: #fdf6ec;
    border-radius: 4px;
    color: #e6a341;
    padding: 12px 10px;
    margin-bottom: 10px;
    i {
      font-size: 24px;
      display: flex;
      margin-right: 8px;
    }
    .top {
      font-size: 14px;
      margin-bottom: 5px;
    }
    p {
      font-size: 12px;
    }
  }
  .title {
    font-size: 21px;
    margin-bottom: 10px;
    font-family: $bold;
    color: #282c34;
    padding-bottom: 13px;
    border-bottom: 1px solid #282c34;
  }
  .login-btn {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    font-family: $bold;
    color: #ffffff;
    border: 0;
    margin-top: 5px;
  }
  .text-tips {
    color: #ff4d4f;
    margin: -5px 0 10px;
    font-family: $bold;
  }
}
