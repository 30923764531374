@import "~@/erp/styles/variables/variables.scss";














































































































































$bold: 'PingFang Bold';
.login-content {
  width: 960px;
  height: 464px;
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 2px 11px 6px rgba(234, 234, 234, 0.5);
  border-radius: 4px;
  overflow: hidden;
  .title {
    font-size: 21px;
    margin-bottom: 20px;
    font-family: $bold;
    color: #282c34;
    padding-bottom: 13px;
    border-bottom: 1px solid #282c34;
    display: flex;
    justify-content: space-between;
    em {
      display: flex;
      align-items: flex-end;
      font-size: 14px;
      color: #666;
      cursor: pointer;
    }
  }
  .login-btn {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    font-family: $bold;
    color: #ffffff;
    border: 0;
    margin-top: 10px;
  }
  .verify {
    display: flex;
    justify-content: space-between;
    height: 46px;
    margin-bottom: 20px;
    .el-input {
      width: 230px;
    }
    .el-button {
      width: 120px;
    }
  }
}
